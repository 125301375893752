import React from 'react';
import { NavDropdown, Form, FormControl, Button } from 'react-bootstrap';

const FilterDropdown = ({
    title,
    options = [],
    selectedItems = [],
    setSelectedItems,
    searchValue,
    setSearchValue,
    multiSelect,
    setMultiSelect,
    handleSelectAll,
    setFilter,
    type = 'string',
}) => {
    const handleItemSelect = (value, event) => {
        if (multiSelect) {
            event.preventDefault();
            event.stopPropagation();
            setSelectedItems((prev) =>
                prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
            );
        } else {
            setSelectedItems([value]);
            if (setFilter) setFilter(value);
        }
    };

    const handleMultiSelectToggle = () => {
        setMultiSelect(!multiSelect);
        setSelectedItems([]);
    };

    const handleClearFilter = () => {
        setSelectedItems([]);
        if (setFilter) setFilter('');
        setSearchValue('');
    };

    const sortOptions = (options) => {
        if (type === 'number') {
            return options.sort((a, b) => parseFloat(a) - parseFloat(b));
        }
        return options.sort((a, b) => a.toString().localeCompare(b.toString()));
    };

    return (
        <NavDropdown title={title} id={`${title.toLowerCase()}-dropdown`} style={{ fontSize: 'medium' }} menuVariant="dark">
            <Form className="px-3">
                <FormControl
                    type="text"
                    placeholder={`Search ${title}...`}
                    value={searchValue || ''}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="mb-2"
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Form.Check
                        type="switch"
                        id={`multi-select-${title.toLowerCase()}-switch`}
                        label="Multi-select"
                        checked={multiSelect}
                        onChange={handleMultiSelectToggle}
                        style={{ fontSize: 'small' }}
                    />
                    <Button
                        size="sm"
                        onClick={handleSelectAll}
                        style={{ width: '65px', fontSize: '11px', marginRight: '5px' }}
                    >
                        Select All
                    </Button>
                    <Button
                        size="sm"
                        variant="danger"
                        onClick={handleClearFilter}
                        style={{ width: '65px', fontSize: '11px' }}
                    >
                        Clear
                    </Button>
                </div>
            </Form>
            {sortOptions(options).map((value, idx) => (
                <NavDropdown.Item
                    key={idx}
                    style={{
                        fontSize: 'medium',
                        width: 'fit-content',
                        color: selectedItems.includes(value) ? 'gold' : 'lightgrey',
                    }}
                    onClick={(e) => handleItemSelect(value, e)}
                >
                    {value}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    );
};

export default FilterDropdown;
