import { connectToDB, StoreData, GetData } from './DBOps';
import * as XLSX from "xlsx";

export const GetJJDRPLocalData = async () => {
    try {
        const db = await connectToDB();
        const data = await GetData(db, 'JJDRP');
        return data[0];
    } catch (error) {
        console.error("Error getting Ventas Data from local storage:", error);
        return null;
    }
};

export const SaveJJDRPData = async (JJDRPPath, JJDRP_Stock, JJDRP_SalesOrders, JJDRP_PurchaseOrders, JJDRP_Transit, JJDRP_Invoiced) => {

    //Get References to the files and read data.
    let arrayBuffedJJDRP = await JJDRPPath.arrayBuffer();
    // let arrayBuffedJJDRP_Stock = await JJDRP_Stock.arrayBuffer();
    // let arrayBuffedJJDRP_SalesOrders = await JJDRP_SalesOrders.arrayBuffer();
    // let arrayBuffedJJDRP_PurchaseOrders = await JJDRP_PurchaseOrders.arrayBuffer();
    // let arrayBuffedJJDRP_Transit = await JJDRP_Transit.arrayBuffer();
    // let arrayBuffedJJDRP_Invoiced = await JJDRP_Invoiced.arrayBuffer();


    let JJDRPBook = XLSX.readFile(arrayBuffedJJDRP);
    // let JJDRPStockBook = XLSX.readFile(arrayBuffedJJDRP_Stock);
    // let JJDRPSalesOrdersBook = XLSX.readFile(arrayBuffedJJDRP_SalesOrders);
    // let JJDRPPurchaseOrdersBook = XLSX.readFile(arrayBuffedJJDRP_PurchaseOrders);
    // let JJDRPTransitBook = XLSX.readFile(arrayBuffedJJDRP_Transit);
    // let JJDRPInvoicedBook = XLSX.readFile(arrayBuffedJJDRP_Invoiced);
    

    let JJDRPSheet = JJDRPBook.Sheets["Full Matrix"];
    // let JJDRPStockSheet = JJDRPStockBook.Sheets["Raw data"];
    // let JJDRPSalesOrdersSheet = JJDRPSalesOrdersBook.Sheets["Sheet1"];
    // let JJDRPPurchaseOrdersSheet = JJDRPPurchaseOrdersBook.Sheets["Sheet1"];
    // let JJDRPTransitSheet = JJDRPTransitBook.Sheets["Sheet1"];
    // let JJDRPInvoicedSheet = JJDRPInvoicedBook.Sheets["Invoices"];


    let JJDRPData = XLSX.utils.sheet_to_json(JJDRPSheet);
    // let JJDRPStockData = XLSX.utils.sheet_to_json(JJDRPStockSheet);
    // let JJDRPSalesOrdersData = XLSX.utils.sheet_to_json(JJDRPSalesOrdersSheet);
    // let JJDRPPurchaseOrdersData = XLSX.utils.sheet_to_json(JJDRPPurchaseOrdersSheet);
    // let JJDRPTransitData = XLSX.utils.sheet_to_json(JJDRPTransitSheet);
    // let JJDRPInvoicedData = XLSX.utils.sheet_to_json(JJDRPInvoicedSheet);


    const _db = await connectToDB();
    try {
        await StoreData(_db, "JJDRP", JJDRPData);
        // await StoreData(_db, "JJDRP_Stock", JJDRPStockData);
        // await StoreData(_db, "JJDRP_SalesOrders", JJDRPSalesOrdersData);
        // await StoreData(_db, "JJDRP_PurchaseOrders", JJDRPPurchaseOrdersData);
        // await StoreData(_db, "JJDRP_Transit", JJDRPTransitData);
        // await StoreData(_db, "JJDRP_Invoiced", JJDRPInvoicedData);
        console.log("JJDRP Data Saved Successfully");
        const JJDRPDataFromDB = await GetData(_db, "JJDRP");
        return JJDRPDataFromDB;
    }   catch (error) {
        console.log(error);
    }
};