import _ from 'lodash';
import XLSX from 'xlsx';

export const parseExcelDate = (excelDate) => {
  if (typeof excelDate === 'number') {
    // Excel serial date number
    // Correct for Excel's incorrect leap year assumption (1900 is not a leap year)
    const excelEpochStart = new Date(Date.UTC(1900, 0, 1));
    const serialOffset = excelDate >= 60 ? excelDate - 2 : excelDate - 1;
    const date = new Date(excelEpochStart.getTime() + serialOffset * 24 * 60 * 60 * 1000);
    return date;
  } else if (typeof excelDate === 'string') {
    // Date string (e.g., '07/01/2022' for UK date)
    const dateParts = excelDate.split('/');
    if (dateParts.length === 3) {
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // Months are 0-based in JS
      const year = parseInt(dateParts[2], 10);
      return new Date(year, month, day);
    } else {
      // Try parsing using Date constructor
      const date = new Date(excelDate);
      return isNaN(date) ? null : date;
    }
  } else {
    return null;
  }
};

export const validateData = (data, dateColumn) => {
  const dates = data
    .map((row) => parseExcelDate(row[dateColumn]))
    .filter((date) => date instanceof Date && !isNaN(date));

  if (dates.length === 0) return false;

  const minDate = new Date(Math.min(...dates));
  const maxDate = new Date(Math.max(...dates));
  const timeDiff = maxDate - minDate;
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  // Check if data spans at least two years (730 days)
  return daysDiff >= 730;
};

export const extractLastTwoYearsData = (data, dateColumn) => {
  const twoYearsAgo = new Date();
  twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

  return data.filter((row) => {
    const date = parseExcelDate(row[dateColumn]);
    return date >= twoYearsAgo && !isNaN(date);
  });
};


/**
 * Calculate mean, stdDev, and coeffVar for each material, 
 * based on monthly (or weekly) sales aggregates.
 */
export const calculateStatistics = (
  data,
  salesColumn, 
  materialColumn, 
  materialDescriptionColumn, 
  dateColumn
) => {
  // 1. Group all rows by "material"
  const groupedByMaterial = _.groupBy(data, materialColumn);

  // 2. For each material, group transactions by YYYY-MM
  const stats = _.map(groupedByMaterial, (entries, material) => {
    // Group the material’s transactions by Month-Year (e.g., "2024-01")
    const monthlyGroups = _.groupBy(entries, (entry) => {
      const date = parseExcelDate(entry[dateColumn]);
      if (!date || isNaN(date)) return 'InvalidDate';

      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      return `${year}-${month}`;
    });

    // 3. Convert each monthly group into a single sales figure
    //    Include 0 if no sales in a particular month
    //    (no need to filter out zero values!)
    const monthlySales = Object.entries(monthlyGroups).map(([yearMonth, monthEntries]) => {
      if (yearMonth === 'InvalidDate') {
        return 0; 
      }
      // Sum up the sales for that month
      return _.sumBy(monthEntries, (e) => {
        const val = parseFloat(e[salesColumn]);
        return isNaN(val) || val < 0 ? 0 : val; // treat negative/invalid as 0
      });
    });

    // 4. Calculate mean, stdDev, and coeffVar
    const mean = monthlySales.length > 0 ? _.mean(monthlySales) : 0;
    const stdDev = monthlySales.length > 1 
      ? Math.sqrt(_.mean(monthlySales.map(val => Math.pow(val - mean, 2))))
      : 0;
    const coeffVar = mean !== 0 ? stdDev / mean : 0;

    // 5. Build a stats object
    const materialDescription = entries[0][materialDescriptionColumn];
    const totalSales = _.sum(monthlySales);

    return {
      material,
      materialDescription,
      mean,
      stdDev,
      coeffVar,
      totalSales,
    };
  });

  // Return array of stats (one entry per material)
  return stats;
};

export const performABCClassification = (stats, abcThresholds) => {
  const { A, B } = abcThresholds;
  const sortedStats = _.orderBy(stats, ['totalSales'], ['desc']);

  const totalSalesAllMaterials = _.sumBy(sortedStats, 'totalSales');
  let cumulativeSales = 0;

  for (let item of sortedStats) {
    cumulativeSales += item.totalSales;
    const cumulativePercentage = (cumulativeSales / totalSalesAllMaterials) * 100;

    let classification = '';
    if (cumulativePercentage <= A) {
      classification = 'A';
    } else if (cumulativePercentage <= B) {
      classification = 'B';
    } else {
      classification = 'C';
    }

    item.classification = classification;
    item.cumulativePercentage = cumulativePercentage; // Add this line
  }

  return sortedStats;
};

export const performXYZClassification = (stats, xyzThresholds) => {
  const { X, Y } = xyzThresholds;

  const classifiedStats = stats.map((item) => {
    let xyzClassification = '';

    if (item.coeffVar <= X) {
      xyzClassification = 'X';
    } else if (item.coeffVar <= Y) {
      xyzClassification = 'Y';
    } else {
      xyzClassification = 'Z';
    }

    return {
      ...item,
      xyzClassification,
    };
  });

  return classifiedStats;
};

export const exportABCXYZToExcel = (filteredStats) => {
  // Define the order of properties you want in the Excel sheet
  const fieldOrder = [
    'material',                // Material name or ID
    'materialDescription',     // Material description
    'totalSales',              // Total sales
    'cumulativePercentage',    // Cumulative percentage
    'mean',                    // Mean sales value
    'stdDev',                  // Standard deviation
    'coeffVar',                 // Coefficient of variation
    'classification',          // ABC classification
    'xyzClassification',
  ];

  // Reorder data in filteredStats according to fieldOrder
  const orderedData = filteredStats.map(item => {
    const orderedItem = {};
    fieldOrder.forEach(field => {
      orderedItem[field] = item[field];
    });
    return orderedItem;
  });

  const fileSafeDynamicDateStringDDMMYYYYY = new Date().toLocaleDateString('en-GB').replace(/\//g, '-');

  // Convert the ordered data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(orderedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'ABC-XYZ Data');

  // Initiate download
  XLSX.writeFile(workbook, `ABC_XYZ_Classification - ${fileSafeDynamicDateStringDDMMYYYYY}.xlsx`);
};
  
