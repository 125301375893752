import React, { useState } from 'react';
import FilterDropdown from '../../../Reusables/FilterDropdown';

const OtherMaterialFilters = ({
    uniqueValues,
    // Finish props
    multiSelectFinish,
    setMultiSelectFinish,
    selectedFinishes,
    setSelectedFinishes,
    setFinishFilter,
    // Thickness props
    multiSelectThickness,
    setMultiSelectThickness,
    selectedThicknesses,
    setSelectedThicknesses,
    setThicknessFilter,
    // Format props
    multiSelectFormat,
    setMultiSelectFormat,
    selectedFormats,
    setSelectedFormats,
    setFormatFilter,
    // Segment props
    multiSelectSegment,
    setMultiSelectSegment,
    selectedSegments,
    setSelectedSegments,
    setSegmentFilter
}) => {
    // Optional search states for each dropdown
    const [finishSearch, setFinishSearch] = useState('');
    const [thicknessSearch, setThicknessSearch] = useState('');
    const [formatSearch, setFormatSearch] = useState('');
    const [segmentSearch, setSegmentSearch] = useState('');

    return (
        <>
            {/* Finish Filter */}
            <FilterDropdown
                title="Finish"
                options={uniqueValues('Acabado').filter(value =>
                    value?.toString().toLowerCase().includes(finishSearch.toLowerCase())
                )}
                selectedItems={selectedFinishes}
                setSelectedItems={setSelectedFinishes}
                searchValue={finishSearch}
                setSearchValue={setFinishSearch}
                multiSelect={multiSelectFinish}
                setMultiSelect={setMultiSelectFinish}
                handleSelectAll={() => setSelectedFinishes(uniqueValues('Acabado'))}
                setFilter={setFinishFilter}
            />

            {/* Thickness Filter */}
            <FilterDropdown
                title="Thickness"
                options={uniqueValues('Espesor').filter(value =>
                    value?.toString().toLowerCase().includes(thicknessSearch.toLowerCase())
                )}
                selectedItems={selectedThicknesses}
                setSelectedItems={setSelectedThicknesses}
                searchValue={thicknessSearch}
                setSearchValue={setThicknessSearch}
                multiSelect={multiSelectThickness}
                setMultiSelect={setMultiSelectThickness}
                handleSelectAll={() => setSelectedThicknesses(uniqueValues('Espesor'))}
                setFilter={setThicknessFilter}
            />

            {/* Format Filter */}
            <FilterDropdown
                title="Format"
                options={uniqueValues('Medida').filter(value =>
                    value?.toString().toLowerCase().includes(formatSearch.toLowerCase())
                )}
                selectedItems={selectedFormats}
                setSelectedItems={setSelectedFormats}
                searchValue={formatSearch}
                setSearchValue={setFormatSearch}
                multiSelect={multiSelectFormat}
                setMultiSelect={setMultiSelectFormat}
                handleSelectAll={() => setSelectedFormats(uniqueValues('Medida'))}
                setFilter={setFormatFilter}
            />

            {/* Segment Filter */}
            <FilterDropdown
                title="Segment"
                options={uniqueValues('Segmento').filter(value =>
                    value?.toString().toLowerCase().includes(segmentSearch.toLowerCase())
                )}
                selectedItems={selectedSegments}
                setSelectedItems={setSelectedSegments}
                searchValue={segmentSearch}
                setSearchValue={setSegmentSearch}
                multiSelect={multiSelectSegment}
                setMultiSelect={setMultiSelectSegment}
                handleSelectAll={() => setSelectedSegments(uniqueValues('Segmento'))}
                setFilter={setSegmentFilter}
            />
        </>
    );
};

export default OtherMaterialFilters;
