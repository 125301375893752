import * as XLSX from "xlsx";
import {connectToDB, StoreData, GetData} from './DBOps'
import {calculateTurnoverLocal, averageInvoiceValueAllCustomerLocal, averageInvoiceValueAllCustomerLocaWithoutDisplaysOrQualityCredits, totalBilledSlabsAllCustomer, averageInvoiceValueAllCustomerLocalNoCredits} from '../Utils/CUBOAnalysisOps'


async function processFile(file, sheetName = "Sheet1", profileHeadersFn = null) {
  // Read the file as an array buffer
  let arrayBuffer = await file.arrayBuffer();
  
  // Parse the workbook from the array buffer
  let workbook = XLSX.read(arrayBuffer, { type: 'array' });

  // Access the specified sheet
  let sheet = workbook.Sheets[sheetName];
  
  // Optionally profile headers and modify the sheet if a function is provided
  if (profileHeadersFn) {
    sheet = profileHeadersFn(sheet);
  }

  // Convert the sheet to JSON data
  return XLSX.utils.sheet_to_json(sheet);
}


export const calculateSizeInBytes = (data) => {
  const json = JSON.stringify(data);
  return new Blob([json]).size;
};

const profileHeadersAndAddClientDescription = (worksheet) => {
  // Get the range of the worksheet (dimensions)
  const range = XLSX.utils.decode_range(worksheet['!ref']);

  // Loop through the header row (assumed to be the first row)
  for (let col = range.s.c; col <= range.e.c; col++) {
    // Get the current cell address in the first row
    const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: col });
    const nextCellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: col + 1 });

    // Get the value of the current cell
    const cell = worksheet[cellAddress];

    // Check if the cell contains "Responsable de Pago" or "Payment Responsible"
    if (cell && (cell.v.includes("Responsable de Pago") || cell.v.includes("Payment Responsible"))) {
      // Set the value of the next cell to "Client Description"
      if (!worksheet[nextCellAddress]) {
        worksheet[nextCellAddress] = { t: 's', v: 'Client Description' };
      }
      break; // We can stop looping once we find and update the header
    }
  }

  return worksheet;
};

export const DynamicOpenExcelFile = async (file, sheetName) => {
  try {
    // Convert the file to an ArrayBuffer for processing
    let arrayBuffedFile = await file.arrayBuffer();
    let Book = XLSX.read(arrayBuffedFile, { type: 'array' });

    // Try to access the specified sheet by name
    let Sheet = Book.Sheets[sheetName];

    // If the specified sheet doesn't exist or has no data, fallback to the first sheet
    if (!Sheet || XLSX.utils.sheet_to_json(Sheet).length === 0) {
      const firstSheetName = Book.SheetNames[0];
      Sheet = Book.Sheets[firstSheetName];

      if (!Sheet) {
        throw new Error("No sheets found in the workbook");
      }

      console.warn(
        `Specified sheet "${sheetName}" not found or is empty. Defaulting to the first sheet: "${firstSheetName}".`
      );
    }

    // Convert the sheet to JSON data
    const data = XLSX.utils.sheet_to_json(Sheet);

    if (data && data.length > 0) {
      return data;
    } else {
      throw new Error("No data found in the selected sheet");
    }
  } catch (error) {
    console.error("Error processing Excel file:", error.message);
    throw error;
  }
};


export const DRPConcept = async (VA05File, IQ09File, ME2NFile, CUBOFile, setValidSetter, setExcelDRPConcept, saveToLocal, overrideCodex = false, excelCodexPath) => {

  try 
  {
    let db;
    if (saveToLocal) {
      const _db = await connectToDB();
      db = _db;
      console.log(db);
    }

    //Get References to the files and read data.
    let VA05Data = await processFile(VA05File);
    let IQ09Data = await processFile(IQ09File);
    let ME2NData = await processFile(ME2NFile);
    let CUBOData = await processFile(CUBOFile, "Sheet1", profileHeadersAndAddClientDescription);
    let codexData;

    if (overrideCodex) {
      codexData = await processFile(excelCodexPath);
    }

    if (saveToLocal && db) {

      await Promise.all([
        StoreData(db, 'VA05', VA05Data),
        StoreData(db, 'IQ09', IQ09Data),
        StoreData(db, 'ME2N', ME2NData),
        StoreData(db, 'CUBO', CUBOData)
      ]);

      if (overrideCodex) {
        await StoreData(db, 'CodexData', codexData);
      }

    }
    else {
      console.log("No database found, cannot save");
      alert("No database found, cannot save");
    }
      
    //becomes ExcelDRPConcept
    const DRPResult = {
        totalStockByMaterialBatchAndSerial: groupByMaterialAndBatchAndSerial(IQ09Data),
        totalOrdersByMaterial: groupOrdersByMaterial(VA05Data),
        totalSalesByMaterial: groupSalesByMaterialAndCustomer(CUBOData),
        totalSalesByCustomer: groupByCustomersAndMaterials(CUBOData),
        totalPOsByMaterial: groupPOsByMaterial(ME2NData),
        salesDataRaw: CUBOData,
        VA05DataRaw: VA05Data,
        PODataRaw: ME2NData,
        IQ09Data:IQ09Data,
        CodexData: codexData
    }

    console.log(DRPResult);

    if (setValidSetter) setValidSetter(true);
    setExcelDRPConcept(DRPResult);

  }     
  catch (error) 
  {
    console.log(error);
    setValidSetter(false);
    alert(error.message);
  }
          
};

export const DRPConceptFromLocal = async (setValidSetter, setExcelDRPConcept) => {

  try 
  {
    const db = await connectToDB();
    const localIQ09Data = await GetData(db, 'IQ09');
    const localVA05Data = await GetData(db, 'VA05');
    const localME2NData = await GetData(db, 'ME2N');
    const localCUBOData = await GetData(db, 'CUBO');
    const localCodexData = await GetData(db, 'CodexData');

    const DRPResult = {
        totalStockByMaterialBatchAndSerial: groupByMaterialAndBatchAndSerial(localIQ09Data[0]),
        totalOrdersByMaterial: groupOrdersByMaterial(localVA05Data[0]),
        totalSalesByMaterial: groupSalesByMaterialAndCustomer(localCUBOData[0]),
        totalSalesByCustomer: groupByCustomersAndMaterials(localCUBOData[0]),
        totalPOsByMaterial: groupPOsByMaterial(localME2NData[0]),
        salesDataRaw: localCUBOData[0],
        VA05DataRaw: localVA05Data[0],
        PODataRaw: localME2NData[0],
        IQ09DataRaw:localIQ09Data[0],
        CodexData: localCodexData[0]
    }

    if (setValidSetter) setValidSetter(true);
      setExcelDRPConcept(DRPResult);
    } 

  catch (error) 
  {
    console.log(error);
    setValidSetter(false);
    alert("something went wrong loading from DRP Local, you'll need to upload files agai!");
  }
}
export const CalculateBudgetAnalysis = (budget, salesData) => {
  let salesTotalValueLocal = 0;
  let _averageInvoiceValueAllCustomerLocal = 0;
  let averageInvoiceValueWithoutDisplaysOrCredits = 0;
  let _averageInvoiceValueAllCustomerLocalNoCredits = 0;
  let _totalBilledSlabsAllCustomer = 0;
  let howManySlabsToSellToMeetBudget = 0;
  let percentageDifference = 0;
  let percentageIncreaseInTurnover = 0;
  let percentageDifferenceRoundedString = "";
  let percentageIncreaseInTurnoverRoundedString = "";
  let increaseInQtyRequiredToSell = 0;

  salesTotalValueLocal = calculateTurnoverLocal(salesData);
  _averageInvoiceValueAllCustomerLocal = averageInvoiceValueAllCustomerLocal(salesData);
  _totalBilledSlabsAllCustomer = totalBilledSlabsAllCustomer(salesData);
  _averageInvoiceValueAllCustomerLocalNoCredits = averageInvoiceValueAllCustomerLocalNoCredits(salesData);
  averageInvoiceValueWithoutDisplaysOrCredits = averageInvoiceValueAllCustomerLocaWithoutDisplaysOrQualityCredits(salesData);

  howManySlabsToSellToMeetBudget = budget / _averageInvoiceValueAllCustomerLocalNoCredits;
  percentageDifference = (budget - salesTotalValueLocal) / budget;
  percentageIncreaseInTurnover = (budget - salesTotalValueLocal) / salesTotalValueLocal;
  percentageDifferenceRoundedString = (percentageDifference * 100).toFixed(2);
  percentageIncreaseInTurnoverRoundedString = (percentageIncreaseInTurnover * 100).toFixed(2);
  increaseInQtyRequiredToSell = howManySlabsToSellToMeetBudget - _totalBilledSlabsAllCustomer

  const budgetAnalysis = {
      salesTotalValueLocal: salesTotalValueLocal,
      averageInvoiceValueAllCustomerLocal: _averageInvoiceValueAllCustomerLocal,
      totalBilledSlabsAllCustomer: _totalBilledSlabsAllCustomer,
      howManySlabsToSellToMeetBudget: howManySlabsToSellToMeetBudget,
      percentageDifference: percentageDifference,
      percentageDifferenceRoundedString: percentageDifferenceRoundedString,
      increaseInQtyRequiredToSell: increaseInQtyRequiredToSell,
      percentageIncreaseInTurnover: percentageIncreaseInTurnover,
      percentageIncreaseInTurnoverRoundedString: percentageIncreaseInTurnoverRoundedString,
      averageInvoiceValueWithoutDisplaysOrCredits: averageInvoiceValueWithoutDisplaysOrCredits,
      averageInvoiceValueAllCustomerLocalNoCredits: _averageInvoiceValueAllCustomerLocalNoCredits,
      budget: parseInt(budget),
  };

  return budgetAnalysis;
};
export const CheckFilesAreAllFromSamePlant = (VA05Data, IQ09Data, ME2NData, CUBOData) => {
  const VA05Plant = VA05Data[0].Plant;
  const IQ09Plant = IQ09Data[0].Plant;
  const ME2NPlant = ME2NData[0].Plant;
  const CUBOPlant = CUBOData[0].Plant;

  if (VA05Plant === IQ09Plant && VA05Plant === ME2NPlant && VA05Plant === CUBOPlant) {
    return true;
  }
  else {
    return false;
  }
}

export const GetAndSetPlantNumber =  (stockByMaterial, setPlant) => {
  let plant = stockByMaterial[0].Center;
  setPlant(""+plant+"");
};
export const JustStock = async (IQ09File, setValidSetter, setExcelJustStock) => {
  
      try {
  
          //Get References to the files and read data.
          let arrayBuffedIQ09 = await IQ09File.arrayBuffer();
          let IQ09Book = XLSX.readFile(arrayBuffedIQ09);
          let IQ09Sheet = IQ09Book.Sheets["Sheet1"];
          let IQ09Data = XLSX.utils.sheet_to_json(IQ09Sheet);
  
  
          const JustStockResult = {
              totalStockByMaterialBatchAndSerial: groupByMaterialAndBatchAndSerial(IQ09Data),
          }
  
  
          if (setValidSetter) setValidSetter(true);
          setExcelJustStock(JustStockResult);
  
      } catch (error) {
          console.log(error);
          setValidSetter(false);
          alert("something went wrong!");
      }
};

//* Grouping Data 
const groupByMaterialAndBatchAndSerial = (stockItems) => {
    const groupedMaterials = {};
  
    stockItems.forEach((item) => {
      const material = item.Material;
      const batch = item.Batch || 'N/A';
      const plant = item['Plant'];
      const anchoCommercial = item['ANCHO_COMERCIAL'];
      const largoCommercial = item['LARGO_COMERCIAL'];
  
      if (!groupedMaterials[material]) {
        groupedMaterials[material] = {
          Description: item['Material Description'],
          Quantity: 1,
          Batches: {},
          Plant: plant,
        };
      } else {
        groupedMaterials[material].Quantity += 1;
      }
  
      if (!groupedMaterials[material].Batches[batch]) {
        groupedMaterials[material].Batches[batch] = {
          Quantity: 1,
          SerialNumbers: [{
            Material: item.Material,
            Batch: item.Batch,
            SerialNumber: item['Serial Number'],
            Tono: item.TONO_TABLA,
            AreaComercial: item.AREA_COMERCIAL,
            StorageLocation: item['Storage Location'],
            AnchoCommercial: anchoCommercial,
            LargoCommercial: largoCommercial, 
          }],
        };
      } else {
        groupedMaterials[material].Batches[batch].Quantity += 1;
        groupedMaterials[material].Batches[batch].SerialNumbers.push({
          Material: item.Material,
          Batch: item.Batch,
          SerialNumber: item['Serial Number'],
          Tono: item.TONO_TABLA,
          AreaComercial: item.AREA_COMERCIAL,
          StorageLocation: item['Storage Location'],
          AnchoCommercial: anchoCommercial,
          LargoCommercial: largoCommercial, 
        });
      }

    });
  
    return Object.keys(groupedMaterials).map((material) => ({
      Material: material,
      Description: groupedMaterials[material].Description,
      Quantity: groupedMaterials[material].Quantity,
      Plant: groupedMaterials[material].Plant,
      Batches: Object.keys(groupedMaterials[material].Batches).map((batch) => ({
        Batch: batch,
        Quantity: groupedMaterials[material].Batches[batch].Quantity,
        SerialNumbers: groupedMaterials[material].Batches[batch].SerialNumbers,
      })),
    }));
};
const groupOrdersByMaterial = (orders) => {
  const groupedOrders = {};

  orders.forEach((order) => {
    const material = order.Material;

    if (!groupedOrders[material]) {
      groupedOrders[material] = {
        materialDescription: order['Material Description'], // Assuming camel case for this property as well
        totalOrders: 0,
        totalOpenQuantity: 0, // New property to store the total open quantity
        orders: [],
      };
    }

    groupedOrders[material].totalOrders += 1;
    groupedOrders[material].totalOpenQuantity += order['Open Dlv Quantity'] || 0; // Summing up open quantities

    const orderDetails = {
      dateOfOrder: order['Created On'],
      deliveryDate: order['Delivery Date'],
      accountName: order['Sold-To Party Name'],
      orderNumber: order['Sales Document'],
      orderPosition: order['Sales Document Item'],
      material: order['Material'],
      materialDescription: order['Material Description'],
      netValueItem: order['Net Value (Item)'],
      openQuantity: order['Open Dlv Quantity'],
      batch: order['Batch'], 
      plant: order['Plant'],
      orderStatus: order['Overall Delivery Status Description'],
    };
    groupedOrders[material].orders.push(orderDetails);
  });

  return Object.keys(groupedOrders).map((material) => ({
    Material: material,
    Description: groupedOrders[material].materialDescription,
    TotalOrders: groupedOrders[material].totalOrders,
    TotalOpenQuantity: groupedOrders[material].totalOpenQuantity,
    Orders: groupedOrders[material].orders,
  }));
};
const groupSalesByMaterialAndCustomer = (salesData) => {
  const groupedMaterials = {};

  salesData.forEach((item) => {
    const material = item.Material;
    const customerIdentifier = item['Payment Responsible Code']; // Adjust based on your actual customer identifier

    if (!groupedMaterials[material]) {
      groupedMaterials[material] = {
        MaterialDescription: item['Material Description'],
        TotalBilledQty: 0,
        NetBilledSum: 0,
        AverageOrderQty: 0,
        Customers: {},
      };
    }

    if (!groupedMaterials[material].Customers[customerIdentifier]) {
      groupedMaterials[material].Customers[customerIdentifier] = {
        CustomerName: item['Payment Responsible Name'], // Adjust property name if needed
        IndividualSpend: 0,
      };
    }

    let quantity = 0;
    if (typeof item['Billed Quantity'] === 'string') {
      quantity = parseFloat(item['Billed Quantity'].replace(/[^0-9.-]/g, ''));
    } else if (typeof item['Billed Quantity'] === 'number') {
      quantity = item['Billed Quantity'];
    }

    let netBilledAmount = 0;
    if (typeof item['Net Amount Billed'] === 'string') {
      netBilledAmount = parseFloat(item['Net Amount Billed'].replace(/[^0-9.-]/g, ''));
    } else if (typeof item['Net Amount Billed'] === 'number') {
      netBilledAmount = item['Net Amount Billed'];
    }

    groupedMaterials[material].TotalBilledQty += quantity;
    groupedMaterials[material].NetBilledSum += netBilledAmount;
    groupedMaterials[material].Customers[customerIdentifier].IndividualSpend += netBilledAmount;
    groupedMaterials[material].AverageOrderQty = groupedMaterials[material].TotalBilledQty / Object.keys(groupedMaterials[material].Customers).length;
  });

  return Object.keys(groupedMaterials).map((material) => ({
    Material: material,
    Description: groupedMaterials[material].MaterialDescription,
    TotalBilledQty: groupedMaterials[material].TotalBilledQty,
    NetBilledSum: groupedMaterials[material].NetBilledSum,
    AverageNetBilled: groupedMaterials[material].NetBilledSum / groupedMaterials[material].TotalBilledQty,
    Customers: Object.values(groupedMaterials[material].Customers), // Extract customer array
  }));
};
const groupByCustomersAndMaterials = (salesData) => {
  const groupedCustomers = {};

  salesData.forEach((item) => {
    const customerIdentifier = item['Payment Responsible Code'] // Adjust based on your actual customer identifier

    if (!groupedCustomers[customerIdentifier]) {
      groupedCustomers[customerIdentifier] = {
        CustomerName: item['Payment Responsible Name'],
        TotalNetSpend: 0,
        TotalQty: 0,
        AverageNetSpend: 0,
        AverageQty: 0,
        Materials: {},
      };
    }

    const material = item.Material;

    if (!groupedCustomers[customerIdentifier].Materials[material]) {
      groupedCustomers[customerIdentifier].Materials[material] = {
        TotalQtySold: 0,
        TotalNetSold: 0,
      };
    }

    let quantity = 0;
    if (typeof item['Billed Quantity'] === 'string') {
      quantity = parseFloat(item['Billed Quantity'].replace(/[^0-9.-]/g, ''));
    } else if (typeof item['Billed Quantity'] === 'number') {
      quantity = item['Billed Quantity'];
    }

    let netBilledAmount = 0;
    if (typeof item['Net Amount Billed'] === 'string') {
      netBilledAmount = parseFloat(item['Net Amount Billed'].replace(/[^0-9.-]/g, ''));
    } else if (typeof item['Net Amount Billed'] === 'number') {
      netBilledAmount = item['Net Amount Billed'];
    }

    groupedCustomers[customerIdentifier].TotalNetSpend += netBilledAmount;
    groupedCustomers[customerIdentifier].TotalQty += quantity;
    groupedCustomers[customerIdentifier].Materials[material].TotalQtySold += quantity;
    groupedCustomers[customerIdentifier].Materials[material].TotalNetSold += netBilledAmount;
  });

  // Calculate averages on customers and materials after iterating through sales data
  for (const customerId in groupedCustomers) {
    const customer = groupedCustomers[customerId];
    customer.AverageNetSpend = customer.TotalNetSpend / customer.TotalQty;
    customer.AverageQty = customer.TotalQty / Object.keys(customer.Materials).length;

    for (const material in customer.Materials) {
      const materialData = customer.Materials[material];
      materialData.AverageNetSold = materialData.TotalNetSold / materialData.TotalQtySold;
    }
  }

  return Object.values(groupedCustomers); // Extract and return customer array
};
const groupPOsByMaterial = (POs) => {
  const groupedPOs = {};

  POs.forEach((PO) => {
    const material = PO.Material;

    if (!groupedPOs[material]) {
      groupedPOs[material] = {
        material: material,
        materialDescription: PO['Short Text'],
        totalPOs: 0,
        totalOrdered: 0,
        totalPendingDelivery: 0,
        POs: [],
      };
    }
    groupedPOs[material].totalPOs += 1;
    const orderQty = PO['Order Quantity'];
    groupedPOs[material].totalOrdered += orderQty;
    groupedPOs[material].totalPendingDelivery += PO['Still to be delivered (qty)']

    groupedPOs[material].POs.push({
      DocumentDate: PO['Document Date'],
      PurchasingDocument: PO["Purchasing Document"],
      POPosition: PO['Item'],
      Plant: PO['Plant'],
      NetPrice: PO['Net Price'],
      OrderQty: PO['Order Quantity'],
      ToDeliveryQty: PO['Still to be delivered (qty)'],
      ToInvoiceQty: PO['Still to be invoiced (qty)'],
    });
  });

  return Object.values(groupedPOs);
};


export const convertExcelDateToJSDate = (excelDate) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const daysBetweenExcelAndJS = 25569; // Days between 1900-01-01 (Excel's start date) and 1970-01-01 (JS's start date)
  const excelDateToMilliseconds = excelDate * millisecondsPerDay;
  return new Date(excelDateToMilliseconds - daysBetweenExcelAndJS * millisecondsPerDay);
};
export function exportToExcel(data, fileTitle) {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert the data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Create a blob from the workbook
  const workbookSaved = XLSX.writeFile(workbook, fileTitle+'.xlsx', { compression: true });
  console.log(workbookSaved);
}
export function exportToExcelWithOmission(data, fileTitle, propertiesToOmit = []) {
  // Filter out properties to omit from each item in data
  console.log(data[0]);
  const filteredData = data.map(item => {
    const filteredItem = {};
    for (const key in item) {
      if (!propertiesToOmit.includes(key)) {
        filteredItem[key] = item[key];
      }
    }
    return filteredItem;
  });

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert the filtered data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(filteredData);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Create a blob from the workbook
  const workbookSaved = XLSX.writeFile(workbook, fileTitle+'.xlsx', { compression: true });
  console.log(workbookSaved);
}


