import React, { useState, useEffect } from 'react';
import './Styles/JJDRP.css';
import Config from './Config';
import JJDRPTable from './JJDRPTable';
import JJDRPNavBar from './Navbar';
import Insights from './Insights';
import Loading from '../../Reusables/Loading';

const columns = [
  'Center',
  'SKU',
  'Descrp. Modelo',
  'Acabado',
  'Espesor',
  'Medida',
  'Segmento',
  'Coverage (Months)',
  'Transit Days',
  'Invoiced 12M<',
  'Invoiced 3M<',
  'Daily Rate',
  'Transit Buffer',
  'Target Stock B4 Grwth',
  'Growth Expectation?',
  'Target Stock After Growth',
  'Mimimum Stock (RO Trigger)',
  'MSIB (Min Slabs in 1 Batch)',
  'Stock Max',
  'Current Available Stock (In Plant)',
  'Current Stock in Transit',
  'Current Purchase Orders',
  'Pending Sales Orders',
  'Current Stock in HQ',
  'Supplemented ATP',
  // 'DIO',
  // 'Target',
  'N2O Raw',
  'Real Need',
  'Bookmatch SKU',
  'Bookmatch Preference',
  'Sister Code',
  'Final Orders to Place',
  'OODev', // Ensure OODev is included in the column definitions
];

function JJDRPMain() {
  const [JJDRPFile, setJJDRPFile] = useState(null);
  const [JJDRP_StockFile, setJJDRP_StockFile] = useState(null);
  const [JJDRP_SalesOrdersFile, setJJDRP_SalesOrdersFile] = useState(null);
  const [JJDRP_PurchaseOrdersFile, setJJDRP_PurchaseOrdersFile] = useState(null);
  const [JJDRP_TransitFile, setJJDRP_TransitFile] = useState(null);
  const [JJDRP_InvoicedFile, setJJDRP_InvoicedFile] = useState(null);

  const JJDRPDataProps = {
    JJDRP_StockFile,
    setJJDRP_StockFile,
    JJDRP_SalesOrdersFile,
    setJJDRP_SalesOrdersFile,
    JJDRP_PurchaseOrdersFile,
    setJJDRP_PurchaseOrdersFile,
    JJDRP_TransitFile,
    setJJDRP_TransitFile,
    JJDRP_InvoicedFile,
    setJJDRP_InvoicedFile
  };
  
  const [loading, setLoading] = useState(false);
  const [JJDRPData, setJJDRPData] = useState([]);
  const [JJDRPDataValid, setJJDRPDataValid] = useState(false);
  const [JJDRPDisplayView, setJJDRPDisplayView] = useState('Config');

  // Single-select filter states
  const [decorFilter, setDecorFilter] = useState('');
  const [centerFilter, setCenterFilter] = useState('');
  const [finishFilter, setFinishFilter] = useState('');
  const [thicknessFilter, setThicknessFilter] = useState('');
  const [formatFilter, setFormatFilter] = useState('');
  const [segmentFilter, setSegmentFilter] = useState('');
  
  // Multi-select states
  const [multiSelectDecor, setMultiSelectDecor] = useState(false);
  const [selectedDecors, setSelectedDecors] = useState([]);
  
  const [multiSelectCenters, setMultiSelectCenters] = useState(false);
  const [selectedCenters, setSelectedCenters] = useState([]);
  
  const [multiSelectFinish, setMultiSelectFinish] = useState(false);
  const [selectedFinishes, setSelectedFinishes] = useState([]);
  
  const [multiSelectThickness, setMultiSelectThickness] = useState(false);
  const [selectedThicknesses, setSelectedThicknesses] = useState([]);
  
  const [multiSelectFormat, setMultiSelectFormat] = useState(false);
  const [selectedFormats, setSelectedFormats] = useState([]);
  
  const [multiSelectSegment, setMultiSelectSegment] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState([]);

  const [visibleColumns, setVisibleColumns] = useState(columns); 
  const [recordsToShow, setRecordsToShow] = useState(150);
  const [showNeedToPlaceOnly, setShowNeedToPlaceOnly] = useState(false);
  const [showOverOrders, setShowOverOrders] = useState(false);
  const [overOrdersSensitivity, setOverOrdersSensitivity] = useState(10);

  const [showColourModal, setShowColourModal] = useState(false);

  useEffect(() => {
    if (JJDRPDataValid) {
      setJJDRPDisplayView('Table');
    }
  }, [JJDRPDataValid]);

  /**
   * Utility function to check if an item passes a single/multi-select filter.
   * @param {string|number} itemValue The field value in the data item.
   * @param {boolean} isMulti Whether multi-select is active for this filter.
   * @param {string[]} selectedValues Multi-select array (e.g. selectedFinishes).
   * @param {string} singleValue Single-select value (e.g. finishFilter).
   * @returns {boolean} True if the item matches the filter, false otherwise.
   */
  const passesFilter = (itemValue, isMulti, selectedValues, singleValue) => {
    if (isMulti) {
      // If nothing is chosen in multi-select, we don't filter anything out.
      if (!selectedValues || selectedValues.length === 0) return true;
      // Otherwise, itemValue must be in the chosen set.
      return selectedValues.includes(itemValue);
    } else {
      // Single-select: If there's no filter set, everything passes.
      if (!singleValue) return true;
      // Otherwise, check if itemValue matches the filter (case-insensitive).
      return String(itemValue).toLowerCase() === String(singleValue).toLowerCase();
    }
  };

  const calculateOODev = (data) => {
    return data.map((item) => {
      const purchaseOrders = Number(item['Current Purchase Orders']);
      const supplementedATP = Number(item['Supplemented ATP']);
      const stockMax = Number(item['Stock Max']);
  
      // Only calculate OODev if purchase orders are greater than 0
      const OODev = purchaseOrders > 0 ? supplementedATP - stockMax : null;
  
      return {
        ...item,
        OODev,
      };
    });
  };

  useEffect(() => {
    if (JJDRPData.length > 0) {
      const updatedData = calculateOODev(JJDRPData);
      setJJDRPData(updatedData);
    }
  }, []);

  useEffect(() => {
    if (showOverOrders && !columns.includes('OODev')) {
      setVisibleColumns((prev) => [...prev, 'OODev']);
    } else if (!showOverOrders && columns.includes('OODev')) {
      setVisibleColumns((prev) => prev.filter((col) => col !== 'OODev'));
    }
  }, [showOverOrders]);

    const applyFilters = (data) => {
      // Step 1: Filter data based on the various filters
      let filteredData = data.filter((item) => {
        const decorMatch = passesFilter(item['Descrp. Modelo'], multiSelectDecor, selectedDecors, decorFilter);
        const centerMatch = passesFilter(item['Center'], multiSelectCenters, selectedCenters, centerFilter);
        const finishMatch = passesFilter(item['Acabado'], multiSelectFinish, selectedFinishes, finishFilter);
        const thickMatch = passesFilter(item['Espesor'], multiSelectThickness, selectedThicknesses, thicknessFilter);
        const formatMatch = passesFilter(item['Medida'], multiSelectFormat, selectedFormats, formatFilter);
        const segmentMatch = passesFilter(item['Segmento'], multiSelectSegment, selectedSegments, segmentFilter);
    
        return decorMatch && centerMatch && finishMatch && thickMatch && formatMatch && segmentMatch;
      });
    
      // Step 2: Mutually exclusive logic for NeedToPlace and ShowOverOrders
      if (showNeedToPlaceOnly) {
        // Apply "Show Need to Place Only" filter
        filteredData = filteredData.filter(
          (item) =>
            Number(item['Final Orders to Place']) > 0 ||
            Number(item['Adjusted Order']) > 0
        );
      } else if (showOverOrders) {
        // Apply "Show OverOrders" logic
        filteredData = filteredData
          .map((item) => ({
            ...item,
            OODev: Number(item['Supplemented ATP']) - Number(item['Stock Max']), // Calculate OODev
          }))
          .filter((item) => 
            item.OODev >= overOrdersSensitivity && Number(item['Current Purchase Orders']) > 0 // Apply both OODev and PO condition
          );
      }
    
      console.log('Filtered Data:', filteredData.length, 'records match the filters.');
      return filteredData;
    };
    

  // Filtered data
  const filteredData = applyFilters(JJDRPData);

  return (
    <div className='-JJDRP-Main-'>
      {loading && <Loading />} {/* Show loading animation when data is being loaded */}
      {JJDRPDataValid && (
        <JJDRPNavBar 
          // Data & validity
          JJDRPData={JJDRPData} 
          setJJDRPData={setJJDRPData} 
          setJJDRPDataValid={setJJDRPDataValid} 
          JJDRPDisplayView={JJDRPDisplayView}
          setJJDRPDisplayView={setJJDRPDisplayView}

          // Single-select filter states
          decorFilter={decorFilter}
          setDecorFilter={setDecorFilter}
          centerFilter={centerFilter}
          setCenterFilter={setCenterFilter}
          finishFilter={finishFilter}
          setFinishFilter={setFinishFilter}
          thicknessFilter={thicknessFilter}
          setThicknessFilter={setThicknessFilter}
          formatFilter={formatFilter}
          setFormatFilter={setFormatFilter}
          segmentFilter={segmentFilter}
          setSegmentFilter={setSegmentFilter}

          // Multi-select states
          multiSelectDecor={multiSelectDecor} 
          setMultiSelectDecor={setMultiSelectDecor}
          selectedDecors={selectedDecors} 
          setSelectedDecors={setSelectedDecors}
          
          multiSelectCenters={multiSelectCenters}
          setMultiSelectCenters={setMultiSelectCenters}
          selectedCenters={selectedCenters}
          setSelectedCenters={setSelectedCenters}

          multiSelectFinish={multiSelectFinish}
          setMultiSelectFinish={setMultiSelectFinish}
          selectedFinishes={selectedFinishes}
          setSelectedFinishes={setSelectedFinishes}

          multiSelectThickness={multiSelectThickness}
          setMultiSelectThickness={setMultiSelectThickness}
          selectedThicknesses={selectedThicknesses}
          setSelectedThicknesses={setSelectedThicknesses}

          multiSelectFormat={multiSelectFormat}
          setMultiSelectFormat={setMultiSelectFormat}
          selectedFormats={selectedFormats}
          setSelectedFormats={setSelectedFormats}

          multiSelectSegment={multiSelectSegment}
          setMultiSelectSegment={setMultiSelectSegment}
          selectedSegments={selectedSegments}
          setSelectedSegments={setSelectedSegments}

          // Columns & display
          columns={columns}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          recordsToShow={recordsToShow}
          setRecordsToShow={setRecordsToShow}
          showNeedToPlaceOnly={showNeedToPlaceOnly}
          setShowNeedToPlaceOnly={setShowNeedToPlaceOnly}
          showOverOrders={showOverOrders}
          setShowOverOrders={setShowOverOrders}
          overOrdersSensitivity={overOrdersSensitivity}
          setOverOrdersSensitivity={setOverOrdersSensitivity}
          setShowColourModal={setShowColourModal}
        />
      )}
      {!JJDRPDataValid && JJDRPDisplayView === 'Config' && !loading && (
        <Config 
          setJJDRPData={setJJDRPData} 
          setJJDRPFile={setJJDRPFile} 
          JJDRPFile={JJDRPFile} 
          JJDRPData={JJDRPData} 
          setJJDRPDataValid={setJJDRPDataValid} 
          setJJDRPDisplayView={setJJDRPDisplayView}
          JJDRPDataProps={JJDRPDataProps}
          setLoading={setLoading}
        />
      )}
      {JJDRPDataValid && JJDRPDisplayView === 'Table' && (
        <JJDRPTable 
          JJDRPData={filteredData} 
          JJDRPDisplayView={JJDRPDisplayView} 
          setJJDRPDisplayView={setJJDRPDisplayView} 
          setJJDRPDataValid={setJJDRPDataValid} 
          setJJDRPData={setJJDRPData}
          columns={columns}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          recordsToShow={recordsToShow}
          showOverOrders={showOverOrders}
          showColourModal={showColourModal}
          setShowColourModal={setShowColourModal}
          
        />
      )}
      {JJDRPDataValid && JJDRPDisplayView === 'Insights' && (
        <Insights 
          JJDRPData={filteredData} 
          JJDRPDisplayView={JJDRPDisplayView} 
          setJJDRPDisplayView={setJJDRPDisplayView} 
          setJJDRPDataValid={setJJDRPDataValid} 
          setJJDRPData={setJJDRPData}
        />
      )}
    </div>
  );
}

export default JJDRPMain;
