import React, { useEffect } from 'react'
import FileInputContainer from '../../Reusables/FileInputContainer'
import { Button, Container } from 'react-bootstrap'
import { GetJJDRPLocalData, SaveJJDRPData } from '../../../Utils/JJDRPOps'

function Config({JJDRPData, setJJDRPData, JJDRPFile, setJJDRPFile, setJJDRPDataValid, JJDRPDataProps, setLoading}) {
  const {
    JJDRP_StockFile,
    setJJDRP_StockFile,
    JJDRP_SalesOrdersFile,
    setJJDRP_SalesOrdersFile,
    JJDRP_PurchaseOrdersFile,
    setJJDRP_PurchaseOrdersFile,
    JJDRP_TransitFile,
    setJJDRP_TransitFile,
    JJDRP_InvoicedFile,
    setJJDRP_InvoicedFile
  } = JJDRPDataProps;
  
  const handleJJDRPDataSave = async () => {
    setLoading(true);
    const data = await SaveJJDRPData(JJDRPFile, /*JJDRP_StockFile, JJDRP_SalesOrdersFile, JJDRP_PurchaseOrdersFile, JJDRP_TransitFile, JJDRP_InvoicedFile*/);
    if (data && data.length > 0) {
      setJJDRPData(data[0]);
      setJJDRPDataValid(true);
    }
    setLoading(false);
  };

  const handleJJDRPLoad = async () => {
    const JJDRPData = await GetJJDRPLocalData();
    if (JJDRPData && JJDRPData.length > 0) {
      setJJDRPData(JJDRPData);
      setJJDRPDataValid(true);
      // setJJDRPFile(null);
      // setJJDRP_StockFile(null);
      // setJJDRP_SalesOrdersFile(null);
      // setJJDRP_PurchaseOrdersFile(null);
      // setJJDRP_TransitFile(null);
      // setJJDRP_InvoicedFile(null);
    } else {
      alert("No JJDRP Data Found, please Upload Data again");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      //retrieves data from JJDRP table in IndexedDB
      await handleJJDRPLoad();
    };
    fetchData();
  }, []);

  return (
    <Container className='data-browser-card'>
      <span>Upload the Excel data file</span>
      <FileInputContainer labelTitle={"JJDRP Data"} selectedFilePath={JJDRPFile} setSelectedFile={setJJDRPFile} />
      {/* <FileInputContainer labelTitle={"JJDRP Stock Data"} selectedFilePath={JJDRP_StockFile} setSelectedFile={setJJDRP_StockFile} />
      <FileInputContainer labelTitle={"JJDRP Sales Orders Data"} selectedFilePath={JJDRP_SalesOrdersFile} setSelectedFile={setJJDRP_SalesOrdersFile} />
      <FileInputContainer labelTitle={"JJDRP Purchase Orders Data"} selectedFilePath={JJDRP_PurchaseOrdersFile} setSelectedFile={setJJDRP_PurchaseOrdersFile} />
      <FileInputContainer labelTitle={"JJDRP Transit Data"} selectedFilePath={JJDRP_TransitFile} setSelectedFile={setJJDRP_TransitFile} />
      <FileInputContainer labelTitle={"JJDRP Invoiced Data"} selectedFilePath={JJDRP_InvoicedFile} setSelectedFile={setJJDRP_InvoicedFile} /> */}
      <Button className='custom-button-gold' variant='warning' style={{ marginBottom: "10px" }} onClick={handleJJDRPDataSave}> Go!</Button>
    </Container>
  );
}

export default Config
