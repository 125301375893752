import React, { useState } from 'react';
import { Navbar, NavDropdown, Nav, Button, Row, Col, Form, Modal } from 'react-bootstrap';
import { RemoveDataFromTable, connectToDB } from '../../../Utils/DBOps';
import FilterDropdown from '../../Reusables/FilterDropdown';
import OtherMaterialFilters from './SubComponents/OtherMaterialFilters';
import { exportToExcelWithOmission } from '../../../Utils/ExcelExportUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/free-solid-svg-icons'; // Import colour palette icon

function JJDRPNavBar({ 
    // Data + View
    JJDRPData, 
    setJJDRPData, 
    JJDRPDisplayView, 
    setJJDRPDisplayView, 
    setJJDRPDataValid,

    // Single-select filters
    decorFilter, 
    setDecorFilter, 
    finishFilter, 
    setFinishFilter, 
    thicknessFilter, 
    setThicknessFilter,
    formatFilter, 
    setFormatFilter, 
    segmentFilter, 
    setSegmentFilter,
    centerFilter, 
    setCenterFilter, 

    // Multi-select states
    selectedDecors, 
    setSelectedDecors, 
    multiSelectDecor, 
    setMultiSelectDecor, 

    selectedCenters, 
    setSelectedCenters, 
    multiSelectCenters, 
    setMultiSelectCenters,

    selectedFinishes,
    setSelectedFinishes,
    multiSelectFinish,
    setMultiSelectFinish,

    selectedThicknesses,
    setSelectedThicknesses,
    multiSelectThickness,
    setMultiSelectThickness,

    selectedFormats,
    setSelectedFormats,
    multiSelectFormat,
    setMultiSelectFormat,

    selectedSegments,
    setSelectedSegments,
    multiSelectSegment,
    setMultiSelectSegment,

    // Columns + Display Settings
    columns, 
    visibleColumns, 
    setVisibleColumns,
    recordsToShow, 
    setRecordsToShow,
    showNeedToPlaceOnly, 
    setShowNeedToPlaceOnly,
    showOverOrders,
    setShowOverOrders,
    overOrdersSensitivity,
    setOverOrdersSensitivity,
    setShowColourModal,

}) {
    // Local states for search inputs
    const [decorSearch, setDecorSearch] = useState('');
    const [centerSearch, setCenterSearch] = useState('');
    const [showExportModal, setShowExportModal] = useState(false);
    const [columnsToOmit, setColumnsToOmit] = useState([]);
    const [clearFiltersBeforeExport, setClearFiltersBeforeExport] = useState(false);

    // Generate unique values for any field in JJDRPData
    const uniqueValues = (field) => [...new Set(JJDRPData.map(item => item[field]))];

    // Clear the entire data set from IndexedDB
    const handleClearData = async () => {
        const _db = await connectToDB();
        await RemoveDataFromTable(_db, "JJDRP");
        await RemoveDataFromTable(_db, "JJDRP_Stock");
        await RemoveDataFromTable(_db, "JJDRP_Transit");
        await RemoveDataFromTable(_db, "JJDRP_PurchaseOrders");
        await RemoveDataFromTable(_db, "JJDRP_SalesOrders");
        await RemoveDataFromTable(_db, "JJDRP_Invoiced");
        setJJDRPData([]);
        setJJDRPDisplayView('Config');
        setJJDRPDataValid(false);
    };

    // Compact label of current selections
    const currentSelections = () => {
        const centerLabel   = selectedCenters.length  > 1 ? 'Multi' : selectedCenters[0]  || 'None';
        const decorLabel    = selectedDecors.length   > 1 ? 'Multi' : selectedDecors[0]   || 'None';
        const finishLabel   = finishFilter   || 'None';
        const thicknessLabel= thicknessFilter|| 'None';
        const formatLabel   = formatFilter   || 'None';
        // Segment is not in the label right now, but you can add if you wish:
        // const segmentLabel  = segmentFilter  || 'None';

        return `Center: ${centerLabel}, Decor: ${decorLabel}, Finish: ${finishLabel}, Thickness: ${thicknessLabel}, Format: ${formatLabel}`;
    };

    // Export function
    const handleExport = () => {
        // Clear filters if selected before exporting
        if (clearFiltersBeforeExport) {
            handleClearFilters();
        }
        exportToExcelWithOmission(JJDRPData, "JJDRP_Report", columnsToOmit);
        setShowExportModal(false);
    };

    // Clear all filters
    const handleClearFilters = () => {
        setDecorFilter('');
        setCenterFilter('');
        setFinishFilter('');
        setThicknessFilter('');
        setFormatFilter('');
        setSegmentFilter('');

        // Clear multi-select arrays
        setSelectedDecors([]);
        setSelectedCenters([]);
        setSelectedFinishes([]);
        setSelectedThicknesses([]);
        setSelectedFormats([]);
        setSelectedSegments([]);

        // Reset local search bars
        setDecorSearch('');
        setCenterSearch('');
    };

    // Export Modal control
    const handleModalClose = () => setShowExportModal(false);
    const handleModalShow = () => setShowExportModal(true);

    // Toggle a column in the visible list
    const handleColumnToggle = (column) => {
        if (visibleColumns.includes(column)) {
          setVisibleColumns(visibleColumns.filter(col => col !== column));
        } else {
          setVisibleColumns([...visibleColumns, column]);
        }
    };

    const toggleFilter = (filterType) => {
        if (filterType === 'NeedToPlace') {
          setShowNeedToPlaceOnly((prev) => !prev); // Toggle state
          if (showOverOrders) setShowOverOrders(false); // Turn off ShowOverOrders if it is on
        } else if (filterType === 'OverOrders') {
          setShowOverOrders((prev) => !prev); // Toggle state
          if (showNeedToPlaceOnly) setShowNeedToPlaceOnly(false); // Turn off ShowNeedToPlaceOnly if it is on
        }
      };
      

    return (
        <Navbar className="JJDRP-navbar" variant="dark" bg="dark" expand="sm">
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                {/* Top row: Title + View switching + Clear/Export buttons */}
                <div style={{ width: '98%', display: "flex" }}>
                    <Navbar.Brand className="navbar-brand-JJDRP" style={{ marginLeft: "15px" }}>
                        JJDRP
                    </Navbar.Brand>
                    <Nav className="mr-auto">
                        <NavDropdown title="Views" id="basic-nav-dropdown" style={{ fontSize: "medium" }} menuVariant='dark'>
                            <NavDropdown.Item 
                                style={{ fontSize: "medium", width: "fit-content" }} 
                                onClick={() => setJJDRPDisplayView('Table')}
                            >
                                Table
                            </NavDropdown.Item>
                            <NavDropdown.Item 
                                style={{ fontSize: "medium", width: "fit-content" }} 
                                onClick={() => setJJDRPDisplayView('Insights')}
                            >
                                Insights
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Col style={{ display: "flex", justifyContent: "right", alignItems: "center", gap: "10px" }}>
                        <Button 
                            variant="warning" 
                            size='sm' 
                            style={{ color: "white", fontWeight: "bolder" }} 
                            onClick={handleClearFilters}
                        >
                            Clear Filters
                        </Button>
                        <Button variant="info" size="sm" onClick={handleModalShow}>
                            Export
                        </Button>
                        <Button variant="danger" size='sm' onClick={handleClearData}>
                            Clear Data
                        </Button>
                    </Col>
                </div>

                {/* Second row: Filters */}
                <Row style={{ display: "flex", width: '100%', marginLeft: "25px" }}>
                    <Nav className="mr-auto">
                        <Navbar.Brand className="navbar-brand-JJDRP" style={{ marginLeft: "15px" }}>
                            Filters
                        </Navbar.Brand>

                        {/* Center Filter */}
                        <FilterDropdown 
                            title="Center"
                            options={uniqueValues('Center').filter(value => 
                                value?.toString().toLowerCase().includes(centerSearch.toLowerCase())
                            )}
                            selectedItems={selectedCenters}
                            setSelectedItems={setSelectedCenters}
                            searchValue={centerSearch}
                            setSearchValue={setCenterSearch}
                            multiSelect={multiSelectCenters}
                            setMultiSelect={setMultiSelectCenters}
                            handleSelectAll={() => setSelectedCenters(uniqueValues('Center'))}
                            setFilter={setCenterFilter}
                        />

                        {/* Decor Filter */}
                        <FilterDropdown 
                            title="Decor Name"
                            options={uniqueValues('Descrp. Modelo').filter(value => 
                                value?.toString().toLowerCase().includes(decorSearch.toLowerCase())
                            )}
                            selectedItems={selectedDecors}
                            setSelectedItems={setSelectedDecors}
                            searchValue={decorSearch}
                            setSearchValue={setDecorSearch}
                            multiSelect={multiSelectDecor}
                            setMultiSelect={setMultiSelectDecor}
                            handleSelectAll={() => setSelectedDecors(uniqueValues('Descrp. Modelo'))}
                            setFilter={setDecorFilter}
                        />

                        {/* Other Material Filters: Finish, Thickness, Format, Segment */}
                        <OtherMaterialFilters 
                            uniqueValues={uniqueValues}

                            // Finish
                            multiSelectFinish={multiSelectFinish}
                            setMultiSelectFinish={setMultiSelectFinish}
                            selectedFinishes={selectedFinishes}
                            setSelectedFinishes={setSelectedFinishes}
                            setFinishFilter={setFinishFilter}

                            // Thickness
                            multiSelectThickness={multiSelectThickness}
                            setMultiSelectThickness={setMultiSelectThickness}
                            selectedThicknesses={selectedThicknesses}
                            setSelectedThicknesses={setSelectedThicknesses}
                            setThicknessFilter={setThicknessFilter}

                            // Format
                            multiSelectFormat={multiSelectFormat}
                            setMultiSelectFormat={setMultiSelectFormat}
                            selectedFormats={selectedFormats}
                            setSelectedFormats={setSelectedFormats}
                            setFormatFilter={setFormatFilter}

                            // Segment
                            multiSelectSegment={multiSelectSegment}
                            setMultiSelectSegment={setMultiSelectSegment}
                            selectedSegments={selectedSegments}
                            setSelectedSegments={setSelectedSegments}
                            setSegmentFilter={setSegmentFilter}
                        />

                        {/* Label showing current selections */}
                        <div style={{
                            color: 'white', 
                            fontSize: 'small', 
                            display: "flex", 
                            justifyContent: "center", 
                            width: "44%", 
                            alignItems: "center"
                        }}>
                            {currentSelections()}
                        </div>

                        {/* Columns & Settings Dropdown */}
                        <div 
                            className='columns-visible-dropdwon' 
                            style={{ marginLeft: "auto", marginRight: "10%", display: "flex" }}
                        >
                            <NavDropdown 
                                title="Columns" 
                                id="columns-dropdown" 
                                style={{ fontSize: "medium" }} 
                                menuVariant='dark'
                            >
                                <Form className="px-3">
                                    {columns.map((column, idx) => (
                                        <Form.Check 
                                            key={idx}
                                            type="switch"
                                            id={`column-switch-${idx}`}
                                            label={column}
                                            checked={visibleColumns.includes(column)}
                                            onChange={() => handleColumnToggle(column)}
                                            style={{fontSize: "13px"}}
                                        />
                                    ))}
                                </Form>
                            </NavDropdown>

                            <NavDropdown 
                                title="Settings" 
                                id="settings-dropdown" 
                                style={{ fontSize: "medium" }} 
                                menuVariant="dark"
                                >
                                {/* Records to Show Slider */}
                                <Form.Group controlId="recordsToShowSlider">
                                    <Form.Label style={{ color: "white", fontSize: "12px" }}>
                                    Records to Show: {recordsToShow}
                                    </Form.Label>
                                    <Form.Control
                                    type="range"
                                    min="1"
                                    max="1500"
                                    value={recordsToShow}
                                    onChange={(e) => setRecordsToShow(Number(e.target.value))}
                                    />
                                </Form.Group>

                                {/* Show Need To Place Only Switch */}
                                <Form.Check 
                                    type="switch"
                                    id="show-need-to-place-only-switch"
                                    label="Show Need To Place Only"
                                    checked={showNeedToPlaceOnly}
                                    onChange={() => toggleFilter('NeedToPlace')} // Use toggleFilter here
                                    style={{ color: "white", fontSize: "12px", marginTop: "15px" }}
                                />

                                {/* Show OverOrders Switch */}
                                <Form.Check 
                                    type="switch"
                                    id="show-over-orders-switch"
                                    label="Show OverOrders"
                                    checked={showOverOrders}
                                    onChange={() => toggleFilter('OverOrders')} // Use toggleFilter here
                                    style={{ color: "white", fontSize: "12px", marginTop: "15px" }}
                                />

                                {/* OverOrders Sensitivity Slider */}
                                {showOverOrders && (
                                    <Form.Group controlId="overOrdersSlider">
                                    <Form.Label style={{ color: "white", fontSize: "12px" }}>
                                        OverOrders Sensitivity (slbs): {overOrdersSensitivity}
                                    </Form.Label>
                                    <Form.Control
                                        type="range"
                                        min="1"
                                        max="150"
                                        value={overOrdersSensitivity}
                                        onChange={(e) => setOverOrdersSensitivity(Number(e.target.value))}
                                        disabled={!showOverOrders} // Disable if ShowOverOrders is off
                                    />
                                    </Form.Group>
                                )}
                                {/*HERE*/}
                                <Form.Group style={{ marginTop: "15px" }}>
                                    <div 
                                        style={{ display: "flex", alignItems: "center", cursor: "pointer" }} 
                                        onClick={() => setShowColourModal(true)} // This opens the colour change modal
                                    >
                                        <FontAwesomeIcon 
                                            icon={faPalette} 
                                            style={{ fontSize: "18px", color: "white", marginRight: "8px" }} 
                                        />
                                        <span style={{ color: "white", fontSize: "12px" }}>
                                            Change column colours
                                        </span>
                                    </div>
                                </Form.Group>
                                </NavDropdown>
                        </div>
                    </Nav>
                </Row>
            </div>

            {/* Export Modal */}
            <Modal show={showExportModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Export Options</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{color: "lightgrey"}}> 
                    <Form>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Label>Select Columns to Omit</Form.Label>
                            {columns.map((column, idx) => (
                                <Form.Check 
                                    key={idx}
                                    type="checkbox"
                                    label={column}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setColumnsToOmit([...columnsToOmit, column]);
                                        } else {
                                            setColumnsToOmit(columnsToOmit.filter(col => col !== column));
                                        }
                                    }}
                                />
                            ))}
                        </Form.Group>
                        <Form.Check 
                            type="checkbox"
                            id="clear-filters-before-export"
                            label="Clear Filters Before Export"
                            checked={clearFiltersBeforeExport}
                            onChange={() => setClearFiltersBeforeExport(!clearFiltersBeforeExport)}
                            style={{ marginTop: "15px" }}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleExport}>
                        Export
                    </Button>
                </Modal.Footer>
            </Modal>
        </Navbar>
    );
}

export default JJDRPNavBar;
