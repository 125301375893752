import React, { useEffect } from 'react'
import {connectToDB, GetData} from '../../../../Utils/DBOps'
import { Card, Modal } from 'react-bootstrap'


function StockCellViewCard({uniqueSelection, cardHeaderText, show, onHide}) {
    useEffect(() => {
        const getStockData = async () => {
            const db = await connectToDB();
            const data = await GetData(db, 'JJDRP_Stock');
            const stockData = data[0];
            
            const filteredData = stockData.filter(item => item['Concat CentroSKU'] === uniqueSelection);
            console.log(filteredData);
        };
        getStockData();
    }, [uniqueSelection])


  return (
        <Modal show={show} onHide={onHide} centered size="lg"> 
        <Card className={"JJDRP_Stock" + " dark-card"} style={{width: "100%"}}>
            <Card.Header className={"JJDRP_Stock_Header"}>{cardHeaderText}</Card.Header>
            <Card.Body className={"JJDRP_Stock_Body"}>
            </Card.Body>
        </Card>
        </Modal>
  )
}

export default StockCellViewCard